import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('Error404'),
      },
      {
            path: '/',
            redirect: '/pitch-deck',
      },
      {
            path: '/pitch-deck',
            name: 'homePage',
            component: lazyLoad('Home'),
            meta: { layout: "default", title: "Home" , requiresAuth: true },
            props: true,
      },
      {
            path: '/qoo-numbers',
            name: 'qooNumbers',
            component: lazyLoad('agenda'),
            meta: { layout: "default", title: "qooNumbers" , requiresAuth: true },
            props: true,
      },
      {
            path: '/current-market',
            name: 'currentMarket',
            component: lazyLoad('CurrentMarket'),
            meta: { layout: "default", title: "CurrentMarket" , requiresAuth: true },
            props: true,
      },
      {
            path: '/clients',
            name: 'clientsPage',
            component: lazyLoad('ClientsPage'),
            meta: { layout: "default", title: "Clients" , requiresAuth: true },
            props: true,
      },
      {
            path: '/next-steps',
            name: 'nextSteps',
            component: lazyLoad('nextSteps'),
            meta: { layout: "default", title: "nextSteps" , requiresAuth: true },
            props: true,
      },
      {
            path: '/benefits',
            name: 'benefitsPage',
            component: lazyLoad('benefitsPage'),
            meta: { layout: "default", title: "benefits" , requiresAuth: true },
            props: true,
      },
      {
            path: '/animations-and-renderings',
            name: 'animationsPage',
            component: lazyLoad('animationsAndRenderings/AnimationsAndRenderings'),
            meta: { layout: "default", title: "Animations and Renderings" , requiresAuth: true },
            props: true,
      },
      {
            path: '/case-studies',
            name: 'caseStudiesPage',
            component: lazyLoad('CaseStudies'),
            meta: { layout: "default", title: "CaseStudies" , requiresAuth: true },
            props: true,
      },
      {
            path: '/qoo-advantages',
            name: 'qooPackages',
            component: lazyLoad('QooPackages'),
            meta: { layout: "default", title: "QooPackages" , requiresAuth: true },
            props: true,
      },
      {
            path: '/qoobox-and-qootouch',
            name: 'qooboxAndQootouch',
            component: lazyLoad('qooboxAndQootouch/QooboxAndQootouch'),
            meta: { layout: "default", title: "qooboxAndQootouch" , requiresAuth: true },
            props: true,
      },
      {
            path: '/virtual-production',
            name: 'virtualProduction',
            component: lazyLoad('VirtualProduction/QooboxAndQootouch'), // QooboxAndQootouch
            meta: { layout: "default", title: "VirtualProduction" , requiresAuth: true },
            props: true,
      },
      {
            path: '/qds-and-tour',
            name: 'qdsAndTours',
            component: lazyLoad('QdsAndTours/QdsAndTours'),
            meta: { layout: "default", title: "QdsAndTours" , requiresAuth: true },
            props: true,
      },
      {
            path: '/virtual-production-video',
            name: 'virtualProductionVideo',
            component: lazyLoad('VirtualProduction'), // QooboxAndQootouch
            meta: { layout: "default", title: "VirtualProductionVideo" , requiresAuth: true },
            props: true,
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;
